:root {

    --ion-color-tertiary-very-light: #D8DCFF;

    --ion-color-success-very-light: rgba(66, 215, 125, 0.1);

    --ion-color-danger-very-light: #FEF3F3;

    --ion-color-main-border: #DBE0E9;

    --ion-color-notes: #92949C;
    --ion-color-notes-rgb: 146,148,156;
    --ion-color-notes-contrast: #ffffff;
    --ion-color-notes-contrast-rgb: 255,255,255;
    --ion-color-notes-shade: #637381;
    --ion-color-notes-tint: #8899A8;
}
