/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #22AD5C;
  --ion-color-primary-rgb: 34,173,92;
  --ion-color-primary-contrast: #FFFFFF;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #2D7239;
  --ion-color-primary-tint: #338141;

  --ion-color-secondary: #428DFF;
  --ion-color-secondary-rgb: 66,141,255;
  --ion-color-secondary-contrast: #FFFFFF;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #1F6BDF;
  --ion-color-secondary-tint: #B5DBFF;

  --ion-color-tertiary: #5260FF;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854E0;
  --ion-color-tertiary-tint: #6370FF;

  --ion-color-success: #2DD36F;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #FFFFFF;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28BA62;
  --ion-color-success-tint: #42D77D;

  --ion-color-warning: #FFC409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #102930;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #FBBF24;
  --ion-color-warning-tint: #FFCA22;

  --ion-color-danger: #EB445A;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #FFFFFF;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #CF3C4F;
  --ion-color-danger-tint: #ED576B;

  --ion-color-light: #F4F5F8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #102930;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #DBE0E9;
  --ion-color-light-tint: #F5F6F9;

  --ion-color-medium: #92949C;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9D9FA6;

  --ion-color-dark: #102930;
  --ion-color-dark-rgb: 16,41,48;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1E2023;
  --ion-color-dark-tint: #034752;


  /** set default font **/
  --ion-font-family: 'Anek Latin', sans-serif;
}
